<nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="collapse navbar-collapse">
        <ul class="nav navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-link" routerLink="news" routerLinkActive="active">News</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="doodles" routerLinkActive="active">Doodles</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="articles" routerLinkActive="active">Articles</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="links" routerLinkActive="active">Links</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://blakeboyd.art">.art</a>
            </li>
        </ul>
    </div>
</nav>

<router-outlet></router-outlet>

<footer class="footer mt-auto">
    <div class="container">
        <p class="small text-center text-muted">Copyright © 2019-2023 <a href="mailto:blake.boyd.website@gmail.com">Blake Boyd</a>. All rights reserved.</p>
    </div>
</footer>