<div class="container">
    <div class="row mb-1">
        <div class="col">
            <span>
                This is a programmatic generator for images similar to the album cover of Tame Impala's album <i>Innerspeaker</i>. Upload an image and tweak the settings to see how it turns out!
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <button type="button" class="btn btn-primary" (click)="getDrosteImage()" [disabled]="isLoading || !inputImageData">Submit</button>
        </div>
        <div class="col">
            <input type="file" (change)="onFileChanged($event)" [attr.accept]="isBrokenFbBrowser ? null : 'image/*'" />
        </div>
        <div class="col">
            <span class="text-danger">{{errorMessage}}</span>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <form class="form-inline">
                <div class="input-group mr-3 mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Iterations (0 to 100)</div>
                    </div>
                    <input value="15" class="form-control" #iterationsInput name="iterationsInput" type="number" min="0" max="100" [(ngModel)]="iterations" />    
                </div>
                
                <div class="input-group mr-3 mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Scale (0.0 to 1.0)</div>
                    </div>
                    <input value="0.85" class="form-control" #scaleFactorInput name="scaleFactorInput" type="number" min="0.0" max="1.0" [(ngModel)]="scaleFactor" />
                </div>
                
                <div class="input-group mr-3 mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Type</div>
                    </div>
                    <select #typeInput name="typeInput" class="custom-select" [(ngModel)]="type">
                        <option selected [ngValue]="'innerspeaker'">Innerspeaker</option>
                        <option [ngValue]="'innerspiral'">Innerspiral</option>
                        <option [ngValue]="'innercircle'">Innercircle</option>
                    </select>
                </div>
            </form>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <div *ngIf="isLoading" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <img *ngIf="!isLoading" class="resultImage" [src]="outputImageData" />
        </div>
    </div>
</div>