<div class="container">
    <div class="row mb-1">
        <div class="col">
            <span>
                The secret date generator 😳
            </span>
            <br>
            <span>
                The colors suck atm: dark blue is selected, light blue is unselected (everything is on by default)
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col btn-group">
            <button *ngFor="let expenseLevel of expenseLevels" (click)="expenseClick(expenseLevel)" [ngClass]="{active : isActiveExpenseLevel(expenseLevel)}" class="btn btn-primary">{{expenseLevel}}</button>
        </div>
        <div class="col btn-group">
            <button *ngFor="let dateType of dateTypes" (click)="typeClick(dateType)" [ngClass]="{active : isActiveDateType(dateType)}" class="btn btn-primary">{{dateType}}</button>
        </div>
        <div class="col btn-group">
            <button *ngFor="let doneBefore$ of doneBefore" (click)="beforeClick(doneBefore$)" [ngClass]="{active : isActiveDoneBefore(doneBefore$)}" class="btn btn-primary">{{doneBefore$}}</button>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <button (click)="generateDate()">Generate a date!</button>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <span>{{resultText}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="hearts">
                <div class="heart"></div>
                <div class="heart"></div>
                <div class="heart"></div>
                <div class="heart"></div>
            </div>
        </div>
    </div>
</div>