import { Component, OnInit } from '@angular/core';
import { DocumentService, HotDate } from 'src/app/document.service';

@Component({
    selector: 'app-dates',
    templateUrl: './dates.component.html',
    styleUrls: ['./dates.component.css']
})
export class DatesComponent implements OnInit {
  
    public dates = [];
    public filteredDates = [];

    public expenseLevels = [];
    public dateTypes = [];
    public doneBefore = [];

    public activeExpenseLevels = [];
    public activeDateTypes = [];
    public activeDoneBefore = [];

    public resultText: string = '';
  
    constructor(private documentService: DocumentService) { }

    ngOnInit(): void {
        this.documentService.getDates().subscribe((x:HotDate[]) =>
            {
                this.dates = x;
                this.filteredDates = x;

                this.expenseLevels = Array.from(new Set<string>(this.dates.map(i => i.ExpenseLevel))).sort();
                this.dateTypes = Array.from(new Set<string>(this.dates.map(i => i.DateType))).sort();
                this.doneBefore = Array.from(new Set<string>(this.dates.map(i => i.DoneBefore))).sort();

                this.activeExpenseLevels = this.expenseLevels;
                this.activeDateTypes = this.dateTypes;
                this.activeDoneBefore = this.doneBefore;
            });
    }

    public expenseClick(item: string): void {
        if (this.activeExpenseLevels.some(x => x == item)) {
            this.activeExpenseLevels = this.activeExpenseLevels.filter(x => x != item);
        } else {
            this.activeExpenseLevels.push(item);
        }
        this.filterDates();
    }

    public typeClick(item: string): void {
        if (this.activeDateTypes.some(x => x == item)) {
            this.activeDateTypes = this.activeDateTypes.filter(x => x != item);
        } else {
            this.activeDateTypes.push(item);
        }
        this.filterDates();
    }

    public beforeClick(item: string): void {
        if (this.activeDoneBefore.some(x => x == item)) {
            this.activeDoneBefore = this.activeDoneBefore.filter(x => x != item);
        } else {
            this.activeDoneBefore.push(item);
        }
        this.filterDates();
    }

    public filterDates(): void {
        this.filteredDates = this.dates.filter(
            i => this.activeExpenseLevels.some(j => j == i.ExpenseLevel)
            && this.activeDateTypes.some(j => j == i.DateType)
            && this.activeDoneBefore.some(j => j == i.DoneBefore)
        );
    }

    public isActiveExpenseLevel(item: string): boolean {
        return this.activeExpenseLevels.some(x => x == item);
    }

    public isActiveDateType(item: string): boolean {
        return this.activeDateTypes.some(x => x == item);
    }

    public isActiveDoneBefore(item: string): boolean {
        return this.activeDoneBefore.some(x => x == item);
    }

    public generateDate(): void {
        if (this.filteredDates.length == 0) {
            this.resultText = 'No results found! D:';
        } else {
            const date: HotDate = this.filteredDates[Math.floor(Math.random()*this.filteredDates.length)];
            this.resultText = `${date.Option} (${date.ExpenseLevel} / ${date.DateType} / ${date.DoneBefore}) (${this.filteredDates.length - 1} other matching options)`;
        }
    }
}
