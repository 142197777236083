import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Article, DocumentService } from '../document.service';
import { HighlightService } from '../highlight.service';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit, AfterViewChecked {
  public articleList: Article[] = [];
  public articleContent: string = '';

  public sidebarHiddenOnMobile = true;
  
  constructor(private route: ActivatedRoute,
              private documentService: DocumentService,
              private highLightService: HighlightService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.documentService.getArticles()
        .subscribe(result => this.articleList = result);
      
      const title = params.get('id');
      
      if (!title) {
        this.sidebarHiddenOnMobile = false;
        return;
      }

      this.documentService.getArticleContent(title)
        .subscribe(result => {
          this.articleContent = result;
        },
        () => this.articleContent = '<p>Sorry, this article wasn\'t found!</p>');
    });
  }

  ngAfterViewChecked(): void {
    this.highLightService.highlightAll();
  }
}
