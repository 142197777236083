<div class="container">
    <ul class="text-center no-bullets">
        <li>
            <a href="https://www.linkedin.com/in/blake-boyd-66620539/">Connect with me on LinkedIn</a>
        </li>
        <li>
            <a href="https://github.com/RandomPrecision1">My GitHub Profile</a>
        </li>
        <li>
            <a href="https://bitbucket.org/RandomPrecision1/">My Bitbucket Profile</a>
        </li>
    </ul>
</div>