import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

export class DrosteRequest {
  iterations: number;
  scaleFactor: number;
  type: string;
  fileData: any;
}

export class DrosteResult {
  success: boolean;
  message: string;
  fileData: any;
}

@Component({
  selector: 'app-droste',
  templateUrl: './droste.component.html',
  styleUrls: ['./droste.component.css']
})
export class DrosteComponent implements OnInit {
  public inputImageData: any = '';
  public outputImageData: any = '';
  public errorMessage: string = '';
  public isLoading = false;

  public iterations: number = 15;
  public scaleFactor: number = 0.85;
  public type: string = 'innerspeaker';

  public isBrokenFbBrowser: boolean = !!(navigator.userAgent && navigator.userAgent.match(/FB/));

  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  public getDrosteImage(): void {
    const site = window.location.protocol + '//' + window.location.hostname;
    const request = new DrosteRequest();
    request.iterations = this.iterations;
    request.scaleFactor = this.scaleFactor;
    request.type = this.type;
    request.fileData = this.inputImageData;

    this.isLoading = true;

    this.httpClient.post<any>(site + ':10000/droste', request)
      .subscribe((res: DrosteResult) => {
        if (res.success) {
          this.outputImageData = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + res.fileData);
          this.errorMessage = '';
          this.isLoading = false;
        } else {
          this.errorMessage = 'Something went wrong :(';
          this.isLoading = false;
        }
      }, error => {
        console.debug(error);

        if (error.statusText) {
          this.errorMessage = error.statusText;
        } else {
          this.errorMessage = 'Unknown error';
        }

        this.isLoading = false;
      });
  }

  public onFileChanged($event: any): void {
    const fr = new FileReader();
    fr.onload = () => this.inputImageData = fr.result;
    fr.readAsDataURL($event.target.files[0]);
  }
}
