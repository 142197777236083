import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-doodles',
  templateUrl: './doodles.component.html',
  styleUrls: ['./doodles.component.css']
})
export class DoodlesComponent implements OnInit {
  public sidebarHiddenOnMobile = true;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sidebarHiddenOnMobile = (this.route.snapshot.children.length > 0 ? true : false);
  }

}
