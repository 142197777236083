import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { NewsComponent } from './news/news.component';
import { DoodlesComponent } from './doodles/doodles.component';
import { LinksComponent } from './links/links.component';
import { ArticlesComponent } from './articles/articles.component';
import { RouterModule, Routes } from '@angular/router';
import { DrosteComponent } from './doodles/droste/droste.component';
import { DatesComponent } from './doodles/dates/dates.component';

export const routerConfig: Routes = [
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'doodles',
    component: DoodlesComponent,
    children: [
      {
        path: 'droste',
        component: DrosteComponent
      },
      {
        path: 'dates',
        component: DatesComponent
      }
    ]
  },
  {
    path: 'articles/:id',
    component: ArticlesComponent
  },
  {
    path: 'articles',
    component: ArticlesComponent
  },
  {
    path: 'links',
    component: LinksComponent
  },
  {
    path: '',
    redirectTo: '/news',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/news',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    NewsComponent,
    DoodlesComponent,
    LinksComponent,
    ArticlesComponent,
    DrosteComponent,
    DatesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routerConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
