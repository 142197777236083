import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class Article {
  title: string;
}

export class HotDate {
  Option: string;
  ExpenseLevel: string;
  DateType: string;
  DoneBefore: string;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(private httpClient: HttpClient) { }

  public getArticles(): Observable<Article[]> {
    return this.httpClient.get<Article[]>('assets/articles/metadata.json');
  }

  public getArticleContent(title: string): Observable<string> {
    return this.httpClient.get('assets/articles/' + title + '.html', {responseType: 'text'});
  }

  public getDates(): Observable<HotDate[]> {
    return this.httpClient.get<HotDate[]>('assets/dates/data.json');
  }
}
