<div class="container-fluid">
    <div class="row d-md-none mb-3">
        <div class="col">
            <nav class="nav flex-column">
                <button class="btn btn-secondary p-1" (click)="sidebarHiddenOnMobile = !sidebarHiddenOnMobile">
                    <i *ngIf="sidebarHiddenOnMobile" class="bi bi-chevron-expand"></i>
                    <i *ngIf="!sidebarHiddenOnMobile" class="bi bi-chevron-contract"></i>
                    {{sidebarHiddenOnMobile ? 'Show Doodles' : 'Hide Doodles'}}
                </button>
            </nav>
        </div>
    </div>
    <div class="row">
        <div [ngClass]="{'d-none d-md-flex': sidebarHiddenOnMobile}" class="col-12 col-md-2">
            <nav class="nav flex-column">
                <a class="p-1 nav-item nav-link" href="doodles/droste">Droste Image Generator</a>
            </nav>
        </div>
        <div class="col-12 d-md-none">
            <hr />
        </div>
        <div class="col-12 col-md-10">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>